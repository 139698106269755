<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Ajustes
        </h3>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="10" offset-lg="1">
        <v-row>
          <template v-for="(section, index) in sections">
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="4"
              :class="{ 'pb-1 pt-1': !$vuetify.breakpoint.mdAndUp }"
              :key="index"
              v-if="printAdminElement(section.admin)"
            >
              <v-card
                class="d-flex align-center card_background"
                link
                height="100%"
                @click="goToSection(section)"
              >
                <v-list-item :to="section.to">
                  <v-avatar color="grey lighten-3" size="35" class="mr-2">
                    <v-icon :color="section.color ? section.color : 'black'">
                      {{ section.icon }}
                    </v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="section.title"
                    ></v-list-item-title>
                    <div class="caption">{{ section.description }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      selectedItem: 1,
      sections: [
        {
          icon: "person",
          title: "Cuenta",
          description: "Detalles de tu cuenta",
          to: "/ajustes/cuenta"
        },
        {
          icon: "business",
          title: "Business",
          description: "Detalles generales de tu negocio",
          to: "/ajustes/business",
          admin: true
        },
        {
          icon: "assignment_ind",
          title: "Usuarios",
          description: "Listado de usuarios",
          to: "/usuarios",
          admin: true
        },
        {
          icon: "store_mall_directory",
          to: "/ajustes/sucursales",
          title: "Sucursales",
          description:
            "Gestiona los lugares donde tienes inventarios y realizas ventas de productos"
        },
        {
          icon: "payment",
          title: "Plan y Facturación",
          to: "/ajustes/facturacion",
          description: "Ve la información de tu plan y gestiona tus pagos",
          admin: true
        },
        {
          icon: "memory",
          title: "App Manager",
          to: "/ajustes/app-manager",
          description: "Corrobora el estatus de tu aplicación"
        },
        {
          icon: "exit_to_app",
          title: "Cerrar sesión",
          color: "error",
          description: "Cierra tu sessión en ssencial POS."
        }
      ]
    };
  },
  computed: {
    ...mapGetters("user", ["isAdminUser"])
  },
  methods: {
    ...mapActions("user", ["signOut"]),
    async goToSection(section) {
      if (section.icon == "exit_to_app") {
        await this.signOut();
      }
    },
    printAdminElement(admin) {
      if (admin) {
        if (this.isAdminUser) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }
};
</script>

<style></style>
